import React from "react"
import { graphql } from 'gatsby'

import Prose from "../../components/utility/prose"
import Seo from "../../components/seo"
import Content from "../../components/content"

const LegalPage = ({data}) => {
  const page = data.strapiTermsOfUse
  const content = page.content
  return (
  <>
    <Seo title={page.title} />
    <Prose>
      <h1 className="mb-14">{page.title}</h1>
    </Prose>
    <Content content={content}></Content>
  </>
)}

export default LegalPage


export const legalPageQuery = graphql`
  query legalPage {
    strapiTermsOfUse {
      id
      title
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
